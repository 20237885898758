<script setup lang="ts">
import type { SbBlokData } from '@storyblok/js';

interface IProps {
    blok: LandingValuePropsBlokInterface;
}
const props = defineProps<IProps>();

const hasVideoSubtitle = computed(() => doesRichTextContainText(props.blok?.videoSubtitle));
</script>

<template>
    <es-container>
        <es-row
            v-editable="blok"
            class="LandingValueProps">
            <es-col
                class="order-lg-last"
                lg="6">
                <es-video
                    class="mb-150"
                    :alt-text="blok.videoAltDescription"
                    :embed-url="blok.videoEmbedUrl.url">
                    <storyblok-image
                        v-if="blok.videoCoverImage?.filename"
                        preload
                        loading="eager"
                        class="d-block w-100 h-auto"
                        sizes="md:530px sm:275px"
                        :src="blok.videoCoverImage.filename"
                        :alt="blok.videoAltDescription" />
                </es-video>
                <rich-text-component
                    v-if="hasVideoSubtitle"
                    class="mb-200 mb-lg-0 text-gray-800"
                    :content="blok.videoSubtitle" />
            </es-col>
            <es-col
                lg="6"
                class="pr-lg-300">
                <storyblok-component
                    v-for="(child, index) in blok.valueProps"
                    :key="child._uid"
                    :blok="child as unknown as SbBlokData"
                    :class="{
                        'mb-200': index < blok.valueProps.length - 1,
                        'mb-0': index >= blok.valueProps.length - 1,
                    }" />
            </es-col>
        </es-row>
    </es-container>
</template>
